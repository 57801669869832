<template lang="html">
    <div class="container-fluid p-5">
        <div class="row mt-2 gap-3">
            <div class="form-group">
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name" v-model="form.name" aria-describedby="name">
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="text" class="form-control" id="email" v-model="form.email" aria-describedby="email">
            </div>
            <div class="form-group">
                <label for="current_password">Current Password</label>
                <input type="text" class="form-control" id="current_password" v-model="form.current_password"
                    aria-describedby="current_password">
            </div>
            <div class="form-group">
                <label for="password">New Password</label>
                <input type="text" class="form-control" id="password" v-model="form.password"
                    aria-describedby="password">
            </div>
            <div class="form-group">
                <label for="confirm_password">Confirm Password</label>
                <input type="text" class="form-control" id="confirm_password" v-model="form.confirm_password"
                    aria-describedby="confirm_password">
            </div>
            <button type="button" @click="updateProfile" class="btn btn-primary" style="width: 10%;margin-left: 12px">Update Profile</button>
        </div>
    </div>
</template>

<script lang="js">
import {
    mapState,
    mapActions
} from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    name: 'profile',
    metaInfo: {
        title: "Home",
        titleTemplate: "%s ← SHRE Agent Profile",
    },
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            form: {
                'name': "",
                "email": "",
                'id': "",
                'current_password': '',
                'password': '',
                'confirm_password': '',
            },
            profile: [],
        }
    },
    computed: {
        ...mapState({})
    },
    methods: {
        ...mapActions({
            profileAction: 'profileAction',
            profileUpdateAction: "profileUpdateAction"
        }),
        async profileDetail() {
            this.isLoading = true;
            await this.profileAction().then(res => {
                if (res.status == "success") {
                    this.profile = res.data
                    this.form.id = res.data.id;
                    this.form.name = res.data.name;
                    this.form.email = res.data.email;
                    this.isLoading = false
                }
            }).catch(err => this.isLoading = true)
        },
        async updateProfile() {
            this.isLoading = true;
            await this.profileUpdateAction(this.form).then(res => {
                if (res.status == "success") {
                    this.profile = res.data
                    this.isLoading = false
                }
            }).catch(err => this.isLoading = true)
        },
    },
    async mounted() {
        this.isLoading = true
        this.profileDetail()
    }
}
</script>


<style scoped lang="scss"></style>